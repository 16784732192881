<script>
import { SuawChapterSummary, SuawModal } from "@/components";
import { GetChapterSummaryById, GetUserChapterMembershipStatus } from "../operations.gql";
import { bottomOfTheHour } from "@/utils/formatting/dates.js";
import * as ChapterApi from "../api.js";
export default {
  name: "ChapterSummaryPipe",
  components: { SuawChapterSummary, SuawModal },
  props: {
    chapterId: {
      type: String,
      required: true
    },
    isOrganizer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      showModal: false,
      modalTitle: "",
      modalConfirmAction: null,
      resultGetChapterSummaryById: {
        id: "",
        title: "",
        place: {
          city: "",
          region: ""
        },
        members: {
          aggregate: {
            count: 0
          }
        },
        upcomingChapterEvents: [
          // {
          //   events: {
          //     aggregate: {
          //       count: 0
          //     }
          //   }
          // }
        ]
      },
      resultGetUserChapterMembershipStatus: [
        // {
        //   id: ""
        // }
      ]
    };
  },
  apollo: {
    resultGetChapterSummaryById: {
      query: GetChapterSummaryById,
      variables() {
        return {
          id: this.chapterId,
          endsAfter: bottomOfTheHour(new Date()).toISOString()
        };
      },
      skip() {
        return !this.chapterId;
      },
      loadingKey: "loadingQueriesCount"
    },
    resultGetUserChapterMembershipStatus: {
      query: GetUserChapterMembershipStatus,
      variables() {
        return {
          chapterId: this.chapterId,
          userId: this.$auth.user.id
        };
      },
      skip() {
        return !this.$auth.isAuthenticated || !this.hasChapter;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasChapter() {
      return !!this.resultGetChapterSummaryById.id;
    },
    chapter() {
      return this.hasChapter ? this.resultGetChapterSummaryById : null;
    },
    title() {
      return this.chapter?.title || "";
    },
    location() {
      return this.chapter?.place ? `${this.chapter.place.city ?? "Unknown City"}, ${this.chapter.place.region ?? "Unknown Region"}` : "Unknown Location";
    },
    eventCount() {
      return this.chapter?.upcomingChapterEvents.reduce((total, eventSeries) => total + (eventSeries.events?.aggregate?.count || 0), 0) || 0;
    },
    memberCount() {
      return this.chapter?.members?.aggregate?.count || 0;
    },
    isChapterMember() {
      return this.resultGetUserChapterMembershipStatus?.length > 0;
    }
  },
  async mounted() {
    const { action: a } = this.$route.query;
    if (a === "join") {
      await this.assignChapterMember();
      const query = { ...this.$route.query };
      delete query["action"];
      this.$router.replace({ path: this.$route.path, query }); // Use replace to avoid full navigation
    }
  },
  methods: {
    onJoinClick() {
      if (!this.$auth.isAuthenticated) {
        this.showAuthModal();
      } else {
        this.showJoinModal();
      }
    },
    showAuthModal() {
      this.showModal = true;
      this.modalTitle = "You must be signed in to join a chapter. You will be redirected to the sign in page.";
      this.modalConfirmAction = this.redirectToSignIn;
    },
    showJoinModal() {
      const action = this.isChapterMember ? this.unassignChapterMember : this.assignChapterMember;
      const actionText = this.isChapterMember ? "unjoin" : "join";
      this.showModal = true;
      this.modalTitle = `Are you sure you want to ${actionText} this chapter?`;
      this.modalConfirmAction = action;
    },
    async assignChapterMember() {
      const result = await ChapterApi.assignChapterMember(this.$auth.user.id, this.chapterId);
      this.handleChapterJoinButtonClick(result);
    },
    async unassignChapterMember() {
      const result = await ChapterApi.unassignChapterMember(this.$auth.user.id, this.chapterId);
      this.handleChapterJoinButtonClick(result);
    },
    async handleChapterJoinButtonClick(result) {
      this.showModal = false;
      if (result.success) {
        await this.$apollo.queries.resultGetUserChapterMembershipStatus.refetch();
        await this.$apollo.queries.resultGetChapterSummaryById.refetch();
        this.$emit("toggle-join");
      } else {
        this.$root.$emit("universal-error-message", result.error);
      }
    },
    onSeriesCreateClick() {
      this.$router.push({
        name: "SeriesCreate",
        params: {
          chapterId: this.chapterId
        },
        query: {
          step: "schedule"
        }
      });
    },
    onEditChapterClick() {
      //!! Need Chapter Edit Page eventually
      // this.$router.push({
      //   name: "ChapterEdit",
      //   params: {
      //     chapterId: this.chapterId
      //   }
      // });
    },
    redirectToSignIn() {
      const redirectUrl = `${this.$route.fullPath}${this.$route.fullPath.includes("?") ? "&" : "?"}action=join`;
      this.$router.push({
        name: "LogIn",
        query: {
          redirectUrl
        }
      });
    },
    onCancelModal() {
      this.showModal = false;
    }
  }
};
</script>

<template>
  <div class="suaw-chapter-summary-container">
    <SuawModal v-if="showModal" :modal-title="modalTitle" modal-type="confirmation" :toggle-button-confirm="modalConfirmAction" :toggle-button-cancel="onCancelModal" />
    <SuawChapterSummary
      :title="title"
      :is-admin="false"
      :is-organizer="isOrganizer"
      :is-joined="isChapterMember"
      :location="location"
      :member-count="memberCount"
      :event-count="eventCount"
      @follow-click="onJoinClick"
      @create-series-click="onSeriesCreateClick"
      @edit-chapter-click="onEditChapterClick"
    />
  </div>
</template>

<style lang="scss" scoped>
// necessary to have both modal and SeriesSummary as siblings within template
.suaw-chapter-summary-container {
  width: 100%;
}
</style>
