<script>
import { SuawThumbnail } from "@/components";
import { GetChapterPhotoById } from "../operations.gql";

export default {
  name: "ChapterThumbnailPipe",
  components: { SuawThumbnail },
  props: {
    chapterId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultGetChapterPhotoById: {
        id: "",
        photo_url: ""
      }
    };
  },
  apollo: {
    resultGetChapterPhotoById: {
      query: GetChapterPhotoById,
      variables() {
        return {
          chapterId: this.chapterId
        };
      },
      skip() {
        return !this.chapterId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasChapter() {
      return !!this.resultGetChapterPhotoById.id;
    },
    chapter() {
      return this.hasChapter ? this.resultGetChapterPhotoById : null;
    },
    photo() {
      return this.chapter?.photo_url || null;
    }
  }
};
</script>

<template>
  <SuawThumbnail size="fill-aspect" :content="photo" />
</template>
