<script>
import { SuawInputGroup, SuawFilterBar, SuawListSection, SuawChapterEventCard, SuawEmptyState } from "@/components";
import { GetChapterEventsById, GetUserChapterEventsRsvpStatus } from "../operations.gql";
import { getTimeZoneAbbreviation } from "@/utils/formatting/dates.js";

export default {
  name: "ChapterEventsListPipe",
  components: { SuawInputGroup, SuawFilterBar, SuawListSection, SuawChapterEventCard, SuawEmptyState },
  props: {
    chapterId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      selectedEventType: "All",
      selectedDate: "Upcoming",
      selectedTimeOfDay: "All",
      filterList: [
        {
          label: "Date:",
          dropdownItems: [
            { text: "Upcoming", filter: "Date" },
            { text: "Past", filter: "Date" },
            { text: "All", filter: "Date" }
          ]
        },
        {
          label: "Time of Day:",
          dropdownItems: [
            { text: "All", filter: "Time of Day" },
            { text: "Morning", filter: "Time of Day" },
            { text: "Afternoon", filter: "Time of Day" },
            { text: "Evening", filter: "Time of Day" }
          ]
        },
        {
          label: "Event Type:",
          dropdownItems: [
            { text: "All", filter: "Event Type" },
            { text: "In-Person", filter: "Event Type" },
            { text: "Online", filter: "Event Type" }
          ]
        },
      ],
      resultGetChapterEventsById: {
        id: "",
        upcomingEvents: [
          // {
          //   events: [
          //     {
          //       id: "",
          //       starts_at: "",
          //       ends_at: "",
          //       is_cancelled: false,
          //       is_virtual: false,
          //       venue_json: {
          //         virtualUrl: "",
          //         virtualMeetingId: "",
          //         virtualMeetingPassword: "",
          //         physicalTitle: "",
          //         physicalAddress: ""
          //       },
          //       event_series: {
          //         id: "",
          //         slug: "",
          //         title: "",
          //         organizers: [
          //           {
          //             user: {
          //               id: "",
          //               display_name: "",
          //               initials: "",
          //               avatar_url: "",
          //               avatar_color: "",
          //               is_avatar_hidden: false
          //               deleted_at: null
          //             }
          //           }
          //         ],
          //         chapter: {
          //           id: "",
          //           slug: ""
          //         }
          //       }
          //     }
          //   ]
          // }
        ]
      },
      resultGetUserChapterEventsRsvpStatus: [
        // {
        //   id: "",
        //   event_occurrence_id: ""
        // }
      ]
    };
  },
  apollo: {
    resultGetChapterEventsById: {
      query: GetChapterEventsById,
      variables() {
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1); // Subtract one year from the current date
        return {
          id: this.chapterId,
          endsAfter: oneYearAgo.toISOString(), // Set endsAfter to one year ago in ISO format
          includeCancelled: false
        };
      },
      skip() {
        return !this.chapterId;
      },
      loadingKey: "loadingQueriesCount"
    },
    resultGetUserChapterEventsRsvpStatus: {
      query: GetUserChapterEventsRsvpStatus,
      variables() {
        return {
          chapterId: this.chapterId,
          userId: this.$auth.user.id
        };
      },
      skip() {
        return !this.$auth.isAuthenticated;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasChapter() {
      return !!this.resultGetChapterEventsById.id;
    },
    chapter() {
      return this.hasChapter ? this.resultGetChapterEventsById : null;
    },
    hasRsvpStatus() {
      return this.resultGetUserChapterEventsRsvpStatus.length > 0;
    },
    rsvpedEventIds() {
      return this.hasRsvpStatus ? this.resultGetUserChapterEventsRsvpStatus.map(rsvp => rsvp.event_occurrence_id) : null;
    },
    upcomingEvents() {
      if (!this.chapter || !this.chapter.upcomingEvents) return [];
      // Flatten all event occurrences from the event_series
      const sortedEvents = this.chapter.upcomingEvents.flatMap(series => series.events).sort((a, b) => new Date(a.starts_at) - new Date(b.starts_at));
      return sortedEvents;
    },
    transformedEvents() {
      return this.upcomingEvents?.map(event => {
        const startsAtDate = new Date(event.starts_at);
        const endsAtDate = new Date(event.ends_at);

        // Options for formatting time with space
        const timeOptionsWithSpace = { hour: "numeric", minute: "numeric", hour12: true };

        // Format the start time for CalendarDate with lowercase "am/pm"
        const startTime = startsAtDate.toLocaleTimeString([], timeOptionsWithSpace).replace(/([AP]M)/, match => match.toLowerCase());
        const timezone = getTimeZoneAbbreviation(startsAtDate, !event.is_virtual ? event.place.timezone : undefined);

        // Get start and end times without space for the duration
        const startTimeNoSpace = startsAtDate
          .toLocaleTimeString([], timeOptionsWithSpace)
          .replace(/ /g, "")
          .toLowerCase();

        const endTimeNoSpace = endsAtDate
          .toLocaleTimeString([], timeOptionsWithSpace)
          .replace(/ /g, "")
          .toLowerCase();

        const duration = `${startTimeNoSpace} - ${endTimeNoSpace}`; // Concatenate times without space

        // Determine location based on whether the event is virtual or not
        const location = event.is_virtual ? "Online Event" : event.venue_json.physicalTitle;
        // Determine event status
        const status = event.is_cancelled ? "cancelled" : new Date(event.ends_at) < new Date() ? "past" : event.is_virtual ? "online" : "in-person";
        // Transform the organizers array
        const organizers = event.event_series.organizers.map(org => ({
          id: org.user.id,
          avatar_url: org.user.avatar_url,
          avatar_color: org.user.avatar_color,
          initials: org.user.initials,
          display_name: org.user.display_name,
          is_avatar_hidden: org.user.is_avatar_hidden
        }));

        return {
          id: event.id,
          title: event.event_series.title,
          status: status,
          month: startsAtDate.getMonth() + 1,
          day: startsAtDate.getDate(),
          isVirtual: event.is_virtual,
          startTime: startTime, // Time for CalendarDate with lowercase am/pm
          startsAt: startsAtDate,
          endsAt: endsAtDate,
          duration: duration, // Duration without space between time and am/pm
          timezone,
          location: location,
          organizers: organizers,
          rsvped: this.rsvpedEventIds ? this.rsvpedEventIds.includes(event.id) : false,
          seriesSlug: event.event_series.slug,
          chapterSlug: event.event_series.chapter.slug
        };
      });
    },
    filteredEvents() {
      return this.transformedEvents
        .filter(event => {
          // Filter by event type
          if (this.selectedEventType === "In-Person" && event.isVirtual) return false;
          if (this.selectedEventType === "Online" && !event.isVirtual) return false;
          return true;
        })
        .filter(event => {
          // Filter by date
          const now = new Date();
          // const eventStartDate = new Date(event.startsAt);
          // const eventEndDate = new Date(event.endsAt);

          if (this.selectedDate === "Upcoming" && event.endsAt < now) return false;
          if (this.selectedDate === "Past" && event.endsAt >= now) return false;
          return true;
        })
        .filter(event => {
          // Get the local hour of the event start time
          const eventHour = event.startsAt.getHours(); // `startsAt` is already a local Date object
          // Apply time of day filter
          if (this.selectedTimeOfDay === "Morning" && (eventHour < 0 || eventHour >= 12)) return false;
          if (this.selectedTimeOfDay === "Afternoon" && (eventHour < 12 || eventHour >= 18)) return false;
          if (this.selectedTimeOfDay === "Evening" && (eventHour < 18 || eventHour >= 24)) return false;

          return true;
        });
    }
  },
  methods: {
    onFilterChange({ text, filter }) {
      if (filter === "Event Type") {
        this.selectedEventType = text;
      } else if (filter === "Date") {
        this.selectedDate = text;
      } else if (filter === "Time of Day") {
        this.selectedTimeOfDay = text;
      }
    },
    onCardClick(card) {
      this.$router.push({
        name: "Series",
        params: {
          chapterSlug: card.chapterSlug,
          eventId: card.id,
          seriesSlug: card.seriesSlug
        }
      });
    }
  }
};
</script>

<template>
  <SuawInputGroup direction="column" group-gap="double">
    <SuawFilterBar :filter-list="filterList" dropdown-direction="left" @item-click="onFilterChange" />
    <SuawEmptyState v-if="transformedEvents.length === 0" message="No Chapter Events" />
    <SuawEmptyState v-else-if="transformedEvents.length > 0 && filteredEvents.length === 0" message="There are no Chapter Events at that time" />
    <SuawListSection v-else>
      <SuawChapterEventCard
        v-for="event in filteredEvents"
        :id="event.id"
        :key="event.id"
        :title="event.title"
        :status="event.status"
        :month="event.month"
        :day="event.day"
        :timezone="event.timezone"
        :start-time="event.startTime"
        :is-virtual="event.isVirtual"
        :duration="event.duration"
        :location="event.location"
        :organizers="event.organizers"
        :is-rsvped="event.rsvped"
        :series-slug="event.seriesSlug"
        :chapter-slug="event.chapterSlug"
        @click="onCardClick"
      />
    </SuawListSection>
  </SuawInputGroup>
</template>
