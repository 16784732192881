<script>
import { SuawInputGroup, SuawTextInput, SuawGridSection, SuawChapterMemberCard, SuawEmptyState } from "@/components";
import { GetChapterMembersListById } from "../operations.gql";
export default {
  name: "ChapterMembersListPipe",
  components: { SuawInputGroup, SuawTextInput, SuawGridSection, SuawChapterMemberCard, SuawEmptyState },
  props: {
    chapterId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      membersListSearchQuery: "",
      resultGetChapterMembersListById: [
        // {
        //   id: "",
        //   user_id: "",
        //   chapter_member_role: "",
        //   user: {
        //     id
        //     display_name
        //     deleted_at
        //     country
        //     event_count
        //     post_count
        //   }
        // }
      ]
    };
  },
  apollo: {
    resultGetChapterMembersListById: {
      query: GetChapterMembersListById,
      variables() {
        return {
          chapterId: this.chapterId
        };
      },
      skip() {
        return !this.chapterId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasChapter() {
      return !!this.resultGetChapterMembersListById.length > 0;
    },
    chapter() {
      return this.hasChapter ? this.resultGetChapterMembersListById : null;
    },
    chapterMembers() {
      const authenticatedUserId = this.$auth.isAuthenticated ? this.$auth.user.id : null;
      const isUserOrganizer = this.resultGetChapterMembersListById.some(
        member => authenticatedUserId && member.user_id === authenticatedUserId && member.chapter_member_role === "organizer"
      );

      return (
        this.chapter?.map(member => ({
          userId: member.user.id,
          displayName: member.user.display_name,
          isDeactivated: member.user.deleted_at !== null,
          isOrganizer: member.chapter_member_role === "organizer",
          // Allow messaging if the member is an organizer or the authenticated user is an organizer, but not if the member is the authenticated user
          canMessage: authenticatedUserId ? (member.chapter_member_role === "organizer" || isUserOrganizer) && member.user.id !== authenticatedUserId : false,
          location: member.user.country,
          eventCount: member.user.event_count?.aggregate?.count || 0,
          postCount: member.user.post_count?.aggregate?.count || 0
        })) || []
      );
    },
    filteredMembersList() {
      const lowerSearchQuery = this.membersListSearchQuery.toLowerCase();
      return this.chapterMembers?.filter(member => member.displayName.toLowerCase().includes(lowerSearchQuery));
    }
  },
  methods: {
    onProfileClick(userId) {
      this.$router.push({
        name: "UserProfile",
        params: {
          userId: userId
        }
      });
    },
    onMessageClick(userId) {
      if (this.$auth.isAuthenticated && this.$auth.user.id !== userId) {
        this.$root.$emit("open-direct-message", { otherUserId: userId });
      }
    }
  }
};
</script>

<template>
  <SuawInputGroup direction="column" group-gap="triple">
    <SuawTextInput v-model="membersListSearchQuery" type="search" size="large" placeholder="Search writers..." />
    <SuawGridSection v-if="filteredMembersList.length > 0">
      <SuawChapterMemberCard
        v-for="member in filteredMembersList"
        :key="member.userId"
        :user-id="member.userId"
        :display-name="member.displayName"
        :is-deactivated="member.isDeactivated"
        :location="member.location"
        :can-message="member.canMessage"
        :event-count="member.eventCount"
        :post-count="member.postCount"
        @profile-click="onProfileClick"
        @message-click="onMessageClick"
      />
    </SuawGridSection>
    <SuawEmptyState v-else message="No members found" />
  </SuawInputGroup>
</template>
